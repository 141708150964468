'use client'
import React, {useEffect} from "react";
import ErrorPage from "@/app/components/ErrorPage";

export default function GlobalError({
  error,
  reset,
}: {
  error: Error
  reset: () => void
}) {
  return (
    <html>
      <body>
        <ErrorPage error={error} buttonClick={reset} />
      </body>
    </html>
  )
}


